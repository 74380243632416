body,html {
  margin: 0;
/*  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif; */
    font-family: 'Josefin Sans', 'Open Sans',sans-serif; 
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

:root {
  --patrimonio-icon-color: #050404;
  --gastronomia-icon-color: #21bdf7;
  --eventos-icon-color: #ee3737;
  --natureza-icon-color: #24ba52;
  --dormir-icon-color: #7b5421;
  --comer-icon-color: #f98649;
  --terra-icon-color: #c48336;
  --mar-icon-color: #21bdf7;
  --inactive-box-color: white;
  
}

#root {
  font-family: 'Josefin Sans', 'Open Sans',sans-serif; 
}

hr {
  color:white !important;
  opacity: 1 !important;
  margin-top: -1vh !important;
  margin-left: 6rem !important;
  width: 37vw;
}

.modal-dialog {
  margin-right:unset !important;
  margin-left:unset !important;
}

.btn-close {
  background-color: #cecece !important
}

.filter-drop {
  -webkit-backdrop-filter: brightness(0.5);
  backdrop-filter: brightness(0.5);
}

.filter-drop#img {
  height: 15vh;
}

.carousel-inner, .active.carousel-item  {
  height:100%;
  width:100%
}

svg {
  stroke-width: 1.4
}

/* 
**************************************************
HEADER - NEEDS SERIOUS IMPROVES
***************************************************
*/

.navbar-right .dropdown-item a {
  text-decoration: none;
  color:var(--bs-gray-dark);
  width: 100%;
  display: inline-block;
}

#menus-navbar {
  text-transform: uppercase;
}


#navbar-style {
  border: 1px transparent;
  padding-bottom: 2px;
  box-shadow: 2px 2px 2px grey;
}

#navbar-separator {
  padding-right : 2.5vw
}

#navbar-contacts a {
  padding-right : 2.5vw;
  color:black;
  text-decoration:none;
}

#navbar-brand {
  padding-left: 5vw
}

.portal-title {
  padding-top: 1vh;
  padding-bottom: 1vh;
}

.portal-title h6{
  color : var(--bs-orange);
}

/*

BANNERS

*/

#categories-banner {
  background-image: url("../img/pesquisa.webp");
  height: 15vh;
  width: 100vw;
  background-repeat: no-repeat;
  background-size: auto;
  align-items: center;
  margin-bottom: 3vh;
  background-position: -15rem -2rem;
}

#categories-banner h1 {
  margin-left : 7vw;
  margin-top : 4vh;
  color:white;
  text-transform: uppercase;
  font-size: 40px;
}

#banner {
  background-image: url("../img/ilha.webp");
  height: 15vh;
  width: 80vw;
  background-repeat: no-repeat;
  background-size: auto;
  background-position: -20rem -28rem;
  align-items: center;
  margin-bottom: 3vh;
}

#banner h1 {
  margin-left : 10vw;
  margin-top : 4.5vh;
  color:white;
  text-transform: uppercase;
}

/* 
**************************************************
LANDING PAGE - NEEDS SERIOUS IMPROVES
***************************************************
*/


#landing-title {
  color : white;
  margin-left: 5.5rem;
  font-size : 160px;
  margin-top: -2rem;
  font-weight: 600;
}

#landing-find {
  color : white;
  margin-left: 19vw;
  font-size : 90px;
  margin-top: 20vh;
}

#landing-subtitle {
    color : white;
    margin-left: 12vw;
    font-size : 24px;
    margin-bottom : 5vh;
}

#landing-intro {
    color : white;
    margin-top : 10vh;
    font-size: 20px;
    text-align:center;
}

#landing-subintro {
    color : white;
    font-size: 20px;
    text-align:center
} 

.landing-img {
  background-image: url("../img/landing.webp");
  background-size: cover;
  background-repeat: no-repeat;
}

.landing-card {
  --bs-card-bg: transparent !important;
}

#landing-card-title {
  text-align : center;
  text-transform: uppercase;
  color: white;
}

.landing-card:hover {
  transform: scale(1.15);
  
}

.landing-card .card-img-top {
  opacity: 0.8;
}

.landing-card .card-title{
  text-align : center;
  text-transform: uppercase;
  color: white;
}

.landing-card-border {
  padding: 7%;
  outline: 2px solid #ffffff;
}

#landing-card-icon {
  margin-bottom: 3vh;
  height: 12vh;
}

#landing-card-bw-icon {
  margin-bottom: 3vh;
  height: 12vh;
  width: 12vw;
}

/* 
**************************************************
EXPLORE MENU - NEEDS SERIOUS IMPROVES
***************************************************
*/

.box-explore {
  border: .2rem solid;
  border-radius: 7px;
  min-width:3vw;
  min-height:10vh; 
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
  border-left-width: 0px;
}

.box-inactive {
  border:none;
  border-radius: 0;
}

.box-icon-patrimonio-in {
  background-color: var(--patrimonio-icon-color);
  border-color: var(--patrimonio-icon-color);
}

.box-icon-gastronomia-in {
  background-color: var(--gastronomia-icon-color);
  border-color: var(--gastronomia-icon-color);
}

.box-icon-natureza-in {
  background-color: var(--natureza-icon-color);
  border-color: var(--natureza-icon-color);
}

.box-icon-eventos-in {
  background-color: var(--eventos-icon-color);
  border-color: var(--eventos-icon-color);
}

.box-icon-dormir-in {
  background-color: var(--dormir-icon-color);
  border-color: var(--dormir-icon-color);
}

.box-icon-comer-in {
  background-color: var(--comer-icon-color);
  border-color: var(--comer-icon-color);
}

.box-icon-patrimonio-ov {
  background-color: var(--patrimonio-icon-color);
  stroke: var(--inactive-box-color);
}

.box-icon-gastronomia-ov {
  background-color: var(--gastronomia-icon-color);
  stroke: var(--inactive-box-color);
}

.box-icon-natureza-ov {
  background-color: var(--natureza-icon-color);
  stroke: var(--inactive-box-color);
}

.box-icon-eventos-ov {
  background-color: var(--eventos-icon-color);
  stroke: var(--inactive-box-color);
}

.box-icon-comer-ov {
  background-color: var(--comer-icon-color);
  stroke: var(--inactive-box-color);
}

.box-icon-dormir-ov {
  background-color: var(--dormir-icon-color);
  stroke: var(--inactive-box-color);
}

.card-background {
  background-color: #050404;
}

.card-background .card-text {
  color: white;
}

#inner-box {
  border-style: inset;
  border-color: black;
}

#flex-row {
  padding-bottom: 0.5vh;
}

.box-style {
  min-width:15vw;
  max-height: 10vh;
  margin-left: .3rem;
  align-items: flex-end;
  text-overflow: clip;
}

.box-style h4 {
  color:white;
  padding-right: 4%;
  text-transform: uppercase;
  direction: rtl;
  font-size: 1.25rem;
}

#flex-style {
  flex-basis: 10rem;
}

.flex-read {
  flex-basis: 22rem;
}

/*

ENTITY PAGE

*/

#entity-title  {
  font-size: 45px;
  font-weight: bold;
  margin-bottom: 7vh;
  margin-top: 5vh;
  text-transform: uppercase;
}

#entity-related {
  margin-bottom: 2vh;
  margin-top: 5vh;
}


#entity-par {
  text-align: justify;
  margin-right: 1.5vw;
}

.modal-dialog .modal-content {
  width:90vw;
  background-color: #050404;
  margin-left:5vw;
}

#side-carousel {
  margin-top: -18vh;
}

#entity-img {
  object-fit:cover;
  height:100%;
  max-width: 31vw;
  padding-left:1vw;

}

#entity-modal-img {
  height: 80vh;
  width: 90vw;
  object-fit: contain;
}

#entity-carousel {
  margin-top: -15%;
  object-fit: cover;
  height: 184.5vh;
  width: 30vw;
}

.modal-footer {
  justify-content: center !important;
}

/*

CORVO PAGE

*/

#article-text {
  padding-left: 3vw;
}

#articles-subtitle {
  /*
  margin-top: 3vh;
  margin-bottom: 3vh;
  */
  padding-top: 3vh;
  padding-bottom: 3vh;
  text-transform: uppercase;
  font-weight: bold;
}


/*

ACTIVE BOXES

*/

.box-patrimonio {
  background-color : var(--patrimonio-icon-color);
}

.box-gastronomia {
  background-color : var(--gastronomia-icon-color);
}

.box-natureza {
  background-color : var(--natureza-icon-color);
}

.box-eventos {
  background-color : var(--eventos-icon-color);
}

.box-dormir {
  background-color : var(--dormir-icon-color);
}

.box-comer {
  background-color : var(--comer-icon-color);
}

.box-unpad {
  margin-left:0;
}

.leaflet-container {
  height:30vh;
  width:60vw;
  margin-top: 5rem;
}


/*

SEARCH PAGE

*/

.results {
  padding-bottom: 2vh;
}

.results h3 {
  text-transform: uppercase;
  font-weight: bold;
  text-overflow: ellipsis;
}

.results p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
          line-clamp: 2; 
  -webkit-box-orient: vertical;
}

.results img {
  height: 18vh;
  width: 100vh;
  object-fit: cover;
}

#results-search {
  margin-left: 64.5vw;
  margin-top: -7vh;
}

.breadcrumb-item a {
  color: white !important;
}

.breadcrumb-item + .breadcrumb-item::before {
  color:white !important;
}

/*

CONTACTS PAGE

*/

.item-infos {
  margin-top:5vh;
}

.item-infos svg {
  height: 1.5rem;
  width: 1.5rem;
}

.item-infos #fb:hover{
  fill:#4267B2;
}

.item-infos #tripad:hover {
  color: #00AF87;
}

.item-infos #phone:hover {
  fill: #99cc33;
}

.item-infos #pin:hover {
  stroke: #960a0a;
}

.item-infos #mail:hover {
  fill:#a4eaf0;
}

.item-infos #insta:hover {
  fill: #bc2a8d;
}

.contacts-info {
  margin-top: 1.3rem;
  margin-bottom: 1.3rem;
}

.contacts-info br {
  margin-bottom: 1rem;
}

.contacts-info svg {
  height: 1.5rem;
  width: 1.5rem;
  margin-left: .5rem;
  margin-right: .5rem;
}

.contacts-info #phone:hover {
  fill: #99cc33;
}

.contacts-info #pin:hover {
  stroke: #960a0a;
}

.contacts-info #mail:hover {
  fill:#a4eaf0;
}

#contacts-carousel {
  margin-top: -13%;
  object-fit: cover;
  height: 115vh;
  width: 30vw;
  margin-left: 2rem;
}