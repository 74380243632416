@media only screen and (min-width: 992px) {
  .portal-title {
    margin-left: 0px !important;
  }
}

@media only screen and (orientation:portrait) and (min-width:768px) and (max-width: 1280px) {
    /* FOR TABLETS */
    
    .dropdown-item {
      white-space: inherit !important;
    }

    .btn-close {
      background-color: #cecece !important
    }

    .navbar-collapse {
      display: flex;
      flex-direction: column;
      align-items: flex-end !important;
      margin-bottom: 1vh;
    }
 
    .dropdown-menu[data-bs-popper] {
      margin-left: -11vw;
    }


    #navbar-separator {
      text-align: end;
      padding-bottom: .3rem;
    }

    #navbar-contacts {
      padding-bottom: .3rem;
    }


    hr {
      color:white !important;
      opacity: 1 !important;
      margin-top: -1vh !important;
      margin-left: 2rem !important;
      width: 53vw;
    }

    /* HEADER */

    #banner {
      background-image: url("../img/comer.jpg");
      height: 20vh;
      margin-bottom: 8vh;
    }

    #banner h1 {
      margin-top: 2vw;
    }

    .portal-title h4 {
      margin-left: -38vw;
    }

    .portal-title h6{
      margin-left: -38vw;
    }

    #landing-find {
      color : white;
      margin-left: 14vw;
      font-size : 70px;
      margin-top: 10vh;
      
    }

    #landing-title {
      margin-left: 2vw;
      font-size : 100px;
      margin-top: -1.5rem;
    }
  
    #landing-subtitle {
      font-size : 13px;
      margin-bottom : 5vh;
    }

    #landing-intro {
      margin-top : 10vh;
      margin-left: 3vw;
      font-size: 11px; 
      text-align: center;
    }

    #landing-subintro {
      font-size: 14px;
      text-align:center;

    }
  
    .landing-card .card-title{
      font-size: 13px;
    }

    #landing-card-icon {
      margin-bottom: 1vh;
      height: 8vh;
    }

    #landing-card-bw-icon {
      height: 8vh;
      width: 15vw;
      margin-top: 0.1rem;
      margin-bottom:1vh
    }
    

    #articles-subtitle {
      margin-top: 1.5vh;
      margin-bottom: 3vh;
      text-transform: uppercase;
      font-weight: bold;
    }
  
    #entity-title {
      font-size: 20px;
      font-weight: bolder;
      margin-bottom: 7vh;
      margin-top: 5vh;
    }

    #entity-img {
      height:40vh;
      width: 100vw; 
      object-fit: cover; 
      margin-left: unset;
      max-width: unset;
    }
  
    #article-text {
      padding-left: 3vw;
    }
  
    .offcanvas.offcanvas-bottom {
      height: 60vh !important;
    }
  
    .offcanvas-header .btn-close {
      margin-left: 95vw;
    }
  
    .box-explore {
      min-height: 3vh; 
      max-height: 8vh;
    }
  
    .box-style {
      min-height: 3vh;
      max-height: 13vh;
      min-width: 80vw;
      align-items: flex-end;
      text-overflow: clip;
    }

    .box-style h4 {
      padding-top: 3.5vh;
      font-size: 18px;
    }
  
    .box-unpad {
      margin-left:-1%;
    }
  
    #side-carousel {
      margin-top: 0.1rem;
      background-size:cover;
      height:40vh;
      width:100vw;
    }
  
    #entity-title  {
      font-size: 40px;
      font-weight: bold;
      margin-bottom: 3vh;
      margin-top: 4vh;
      margin-left: 3vw;
      margin-right: 3vw;
    }
    
    #entity-par {
      margin-left: 3vw;
      margin-right: 3vw;
      font-size: 18px;
    }

    .entity-par {
      margin-left: .7rem;
      margin-right: .7rem;
    }

    #categories-div {
      background-color: #fff;
      border: .2rem solid #000;
      left: 94vw;
      bottom: 1.5vh;
      padding-bottom: 42px;
      padding-right: 41px;
      position: sticky;
    }

    #categories-button, #categories-div {
      height: 5vh;
      width: 8vw;
      padding-right: .3rem;
      padding-bottom: .3rem;
    }

    .leaflet-container {
      height:40vh;
      width:100vw;
      margin-bottom: 1.5vh;
    }

    #banner {
      height: 12vh;
      margin-bottom: 3vh;
    }

    #banner h1 {
      margin-bottom:4vh;
    }

    .box-patrimonio {
      background-color : var(--patrimonio-icon-color);
      width: 86.5vw;
    }
    
    .box-gastronomia {
      background-color : var(--gastronomia-icon-color);
      width: 86.5vw;
    }
    
    .box-natureza {
      background-color : var(--natureza-icon-color);
      width: 86.5vw;
    }
    
    .box-eventos {
      background-color : var(--eventos-icon-color);
      width: 86.5vw;
    }
    
    .box-dormir {
      background-color : var(--dormir-icon-color);
      width: 86.5vw;
    }
    

    .box-icon-patrimonio-in {
      border-color: var(--patrimonio-icon-color);
    }
  
    .box-icon-gastronomia-in {
      border-color: var(--gastronomia-icon-color);
    }
    
    .box-icon-natureza-in {
      border-color: var(--natureza-icon-color);
    }
    
    .box-icon-eventos-in {
      border-color: var(--eventos-icon-color);
    }
    
    .box-icon-dormir-in {
      border-color: var(--dormir-icon-color);
    }
    
    .box-icon-comer-in {
      border-color: var(--comer-icon-color);
    }

    .card-container {
      margin-top:1vh;
    }

    /*

    SEARCH PAGE

    */

    .results h3 {
      text-transform: uppercase;
      font-weight: bold;
      text-overflow: ellipsis;
    }

    .results h5 {
      margin-top: -1vh;
    }
    .results img {
      height: 12vh;
    }

    #results-search {
      margin-left: 80vw;
      margin-top: -6.3vh;
    }

    .filter-drop#img, #categories-banner {
      height: 10vh;
    }

    #categories-banner h1 {
      margin-top: 3vh;
      font-size: 30px;
    }

  }

  /*

  LANDSCAPE

  */

  @media only screen and (orientation:landscape) and (max-width: 1280px) and (min-height:600px)  {

    .dropdown-item {
      white-space: inherit !important;
    }

    #navbar-style {
      font-size: 13px;
    }

    #navbar-separator {
      text-align: end;
      padding-bottom: .3rem;
    }

    .portal-title h4{
      font-size: 20px;
    }

    .portal-title h6{
      font-size: 12px;
    }

    .btn-close {
      background-color: #cecece !important
    }

    hr {
      color:white !important;
      opacity: 1 !important;
      margin-top: -2vh !important;
      margin-left: 5vw !important;
      width: 40vw;
    }
    
    #landing-title {
      margin-left: 4vw;
      font-size : 100px;
      margin-top: -1rem;
    }
  
    #landing-subtitle {
      font-size : 20px;
      margin-bottom : 5vh;
      margin-left:6vw;
    }

    #landing-find {
      color : white;
      margin-left: 15vw;
      font-size : 70px;
      margin-top: 15vh;
    }
  
    #landing-intro {
      font-size: 17px;
      margin-top: 5vh;
      margin-bottom: 2vh; 
    }
  
    #landing-subintro {
      font-size: 20px;
      text-align:center
    }
    
    .landing-card .card-title{
      font-size: 13px;
    }
  
    #landing-card-icon {
      margin-bottom: -0.5vh;
      height: 15vh;
      margin-top: -1.5vh;
    }
  
    #landing-card-bw-icon {
      height: 11vh;
      padding-top: .5rem;
      margin-bottom: 2vh;
    }

    .box-explore {
      border: .2rem solid;
      border-radius: 5px;
      min-height: 10vh; 
      max-height: 14vh;
      max-width: 8vw;
    }
  
    .box-style {
      min-height: 3vh;
      max-height: 15vh;
      min-width: 90vw;
      align-items: flex-end;
      text-overflow: clip;
    }
  
    .box-style h4 {
      font-size: 18px;
      padding-top: 3.5vh;
    }
  
    .offcanvas.offcanvas-bottom {
      height: 60vh !important;
    }
  
    .offcanvas-header .btn-close {
      margin-left: 93vw;
    }
  
    .box-unpad {
      margin-left: -1%;
    }
  
    #entity-img {
      height:70vh;
      width: 100vw;
      object-fit: cover; 
      margin-left: unset;
      max-width: unset;
    }
  
    .leaflet-container {
      height:40vh;
      width:100vw;
      margin-bottom: 2vh;
    }
  
    #side-carousel {
      margin-top: .1rem;
    }
  
    #categories-div {
      background-color: #fff;
      border: .2rem solid #000;
      left: 94.9vw;
      bottom: 1.5vh;
      padding-bottom: 42px;
      padding-right: 41px;
      position: sticky;
    }

    #categories-button, #categories-div {
      height: 5vh;
      width: 5vw;
      padding-right: .3rem;
    }
  
    #entity-title  {
      font-size: 50px;
      margin-bottom: 3vh;
      margin-top: 4vh;
      margin-left: 1.5vw;
    }
  
    #entity-par {
      font-size: 20px;
      margin-left: .7rem;
      margin-right: .7rem;
    }

    .entity-par {
      margin-left: .7rem;
      margin-right: .7rem;
    }
  
    #articles-subtitle {
      margin-bottom: 4vh !important;
    }
  
    .portal-title {
      margin-left: -50vw;
    }
  
    .box-patrimonio {
      background-color : var(--patrimonio-icon-color);
      width: 90vw;
    }
    
    .box-gastronomia {
      background-color : var(--gastronomia-icon-color);
      width: 90vw;
    }
    
    .box-natureza {
      background-color : var(--natureza-icon-color);
      width: 90vw;
    }
    
    .box-eventos {
      background-color : var(--eventos-icon-color);
      width: 90vw;
    }
    
    .box-dormir {
      background-color : var(--dormir-icon-color);
      width: 90vw;
    }  
  
    .box-icon-patrimonio-in {
      border-color: var(--patrimonio-icon-color);
    }
  
    .box-icon-gastronomia-in {
      border-color: var(--gastronomia-icon-color);
    }
    
    .box-icon-natureza-in {
      border-color: var(--natureza-icon-color);
    }
    
    .box-icon-eventos-in {
      border-color: var(--eventos-icon-color);
    }
    
    .box-icon-dormir-in {
      border-color: var(--dormir-icon-color);
    }
    
    .box-icon-comer-in {
      border-color: var(--comer-icon-color);
    }

    .card-container {
      margin-top:1vh;
    }

    /*
    SEARCH PAGE
    */

    #results-search {
      margin-left: 63.5vw;
      margin-top: -8vh;
    }
  
  }
