@media only screen and (min-width: 360px) and (max-width:380px) and (orientation:portrait) {
   
    hr {
        width:80%;
    }

    #landing-subtitle {
        margin-left: 5rem;
    }
    
}

@media only screen and (min-width: 381px) and (max-width:421px) and (orientation:portrait) {
   
    hr {
        width:75%
    }
    
}

@media only screen and (min-width: 422px) and (max-width: 442px) and (orientation:portrait) {
   
    hr {
        width:70%
    }
}

@media only screen and (min-width: 381px) and (max-width:450px) and (orientation:portrait) {
   
    #landing-subtitle {
        margin-left: 20%;
    }
    
}

@media only screen and (min-width: 360px) and (max-width: 450px) and (orientation:portrait) {

    #landing-find {
        margin-left: 30%;
    }

    #landing-title {
        margin-left: 10%;
    }

}
