/* PORTRAIT MODE */

@media only screen and (orientation:portrait) and (max-width: 768px) {

  
  hr {
    color:white !important;
    opacity: 1 !important;
    margin-top: -1vh !important;
    margin-left: 15vw !important;
    width: 55vw;
  }

  #navbar-contacts {
    margin-bottom: .3rem;
  }

    /* HEADER */

    .navbar-collapse {
      display: flex;
      flex-direction: column;
      align-items: flex-end !important;
      margin-bottom: 1vh;
    }
    
    .dropdown-item {
      white-space: inherit !important;
    }

    .dropdown-menu[data-bs-popper] {
      margin-left: -25vw;
    }

    .btn-close {
      background-color: #cecece !important
    }

    #navbar-separator {
      text-align: end;
      padding-bottom: .3rem;
    }

    .portal-title h4 {
      padding-left: -9vw;
    }

    .portal-title h6{
      padding-left: -9vw;
      font-size: 12px;
      
    }

    /* CATEGORIES */

    .box-explore {
      border: .2rem solid;
      border-radius: 5px;
      min-height: 5vh; 
      max-width: 15vw;
    }
  
    .box-style {
      min-height: 3vh;
      min-width: 76vw;
      align-items: flex-end;
      text-overflow: clip;
    }

    .box-style h4 {
      font-size: 18px;
      margin-top: 6%;
    }
  
    .box-unpad {
      margin-left:-1%;
    }
    
    .offcanvas.offcanvas-bottom {
      height: 60vh !important;
    }
  
    .offcanvas-header .btn-close {
      margin-left: 85vw;
    }

    .contacts-info {
      text-align:center
    }
     
    #contacts-header-title {
      text-align: center;
    }
    
    #categories-button {
      height: 5vh;
      width: 10vw;
      padding-bottom: 0.2rem;
    }

    #categories-div {
      background-color: #fff;
      border: .2rem solid #000;
      left: 94vw;
      bottom: 1.5vh;
      width: 12vw;
      position: sticky;
    }

    /* LANDING PAGE */

    #landing-find {
      color : white;
      margin-left: 20vw;
      font-size : 50px;
      margin-top: 10vh;
    }

    .landing-card {
      --bs-card-bg:transparent;
    }

    #landing-title {
      margin-left: 5vw;
      font-size : 63px;
      margin-top: -1rem;
    }
  
    #landing-subtitle {
      font-size : 11px;
      margin-bottom : 5vh;
    }

    #landing-intro {
      margin-top : 8vh;
      font-size: 12px; 
    }

    #landing-subintro {
      font-size: 12px;
    }
    
    .landing-card .card-title{
      font-size: 12px;
    }

    .landing-card-border {
      padding: 7%;
      outline: 2px solid #ffffff;
    }

    #landing-card-icon {
      margin-bottom: 1.5vh;
      height: 8vh;
    }

    #landing-card-bw-icon {
      height: 9.5vh;
      width: 18vw;
      padding-bottom: .8rem;
      margin-bottom: 0;
    }
    
      /* ARTICLES PAGE */

    #articles-subtitle {
      margin-bottom: 3vh !important;
    }

    #article-text {
      padding-left: 0;
    }
    
    #article-image {
      margin-top: -15vh;
    }
  
    /* ENTITY PAGE */
    
    #side-carousel {
      margin-top: 0.1rem;
    }
  
    #entity-title  {
      font-size: 20px;
      font-weight: bold;
      margin-bottom: 3vh;
      margin-top: 4vh;
      margin-left: 3vw;
      margin-right: 3vw;
    }
    
    #entity-par {
      font-size: 16px;
      margin-left: .7rem;
      margin-right: .7rem;
    }

    .entity-par {
      margin-left: .7rem;
      margin-right: .7rem;
    }

    #entity-img {
      height:35vh;
      width: 100vw;
      object-fit:cover; 
      max-width: unset;
      margin-left:unset;
    }
  
    .leaflet-container {
      height:40vh;
      width:100vw;
      margin-bottom: 2vh !important;
    }

    .image-gallery {
        margin-top: -6vh;
    }

    .image-gallery-image {
        max-height: 40vh !important;
        max-width: 100vw;
    }

    .box-patrimonio {
      background-color : var(--patrimonio-icon-color);
      width: 86.5vw;
    }
    
    .box-gastronomia {
      background-color : var(--gastronomia-icon-color);
      width: 86.5vw;
    }
    
    .box-natureza {
      background-color : var(--natureza-icon-color);
      width: 86.5vw;
    }
    
    .box-eventos {
      background-color : var(--eventos-icon-color);
      width: 86.5vw;
    }
    
    .box-dormir {
      background-color : var(--dormir-icon-color);
      width: 86.5vw;
    }
    

    .box-icon-patrimonio-in {
      border-color: var(--patrimonio-icon-color);
    }
  
    .box-icon-gastronomia-in {
      border-color: var(--gastronomia-icon-color);
    }
    
    .box-icon-natureza-in {
      border-color: var(--natureza-icon-color);
    }
    
    .box-icon-eventos-in {
      border-color: var(--eventos-icon-color);
    }
    
    .box-icon-dormir-in {
      border-color: var(--dormir-icon-color);
    }
    
    .box-icon-comer-in {
      border-color: var(--comer-icon-color);
    }

    .card-container {
      margin-top:1vh;
    }

    /*
      SEARCH PAGE
    */

    #results-search {
      margin-left: 75vw;
      margin-top: -7.3vh;
    }

    #categories-banner h1 {
      margin-top: 5vh;
      font-size: 30px;
    }

    .results h3 {
      font-size: 18px;
    }

    .results h5 {
      font-size: 18px;
    }

    .results p {
      font-size: 14px;
    }
  
}

@media only screen and (min-width: 411px) and (max-width: 441px) and (orientation:portrait) {

  .portal-title h4 {
      padding-left: -20%;
  }

  .portal-title h6 {
      padding-left: -20%;
  }
}

@media only screen and (orientation:landscape) and (min-width:600px) and (max-width: 767px) {
    .portal-title h4 {
      padding-left: 5rem;
  }

  .portal-title h6 {
      padding-left: 5rem;
  }

  hr {
    margin-left: 6vw !important;
    width: 65vw;
  }
}

/* LANDSCAPE MODE */

@media only screen and (orientation:landscape) and (max-width: 1280px) and (max-height: 500px) {

  .navbar-collapse {
    display: flex;
    flex-direction: column;
    align-items: flex-end !important;
    margin-bottom: 1vh;
  }

  .dropdown-menu[data-bs-popper] {
    margin-left: -11vw;
  }

  .dropdown-item {
    white-space: inherit !important;
  }

  .contacts-info {
    text-align:center
  }

  #contacts-header-title {
    text-align: center;
  }

  #navbar-separator {
    text-align: end;
    padding-bottom: .3rem;
  }

  #navbar-contacts {
    margin-bottom: .3rem;
  }

  #entity-modal-img {
    height: 50vh;
    object-fit: contain;
    width: 90vw;
  }

  hr {
    color:white !important;
    opacity: 1 !important;
    margin-top: -3vh !important;
  }
  
  .btn-close {
    background-color: #cecece !important
  }

  #landing-title {
    margin-left: 4vw;
    font-size : 100px;
    margin-top: -1.5rem;
  }

  #landing-subtitle {
    font-size : 14px;
    margin-left:17vw;
    margin-bottom : 8vh;
  }

  #landing-intro {
    font-size: 12px; 
    text-align: center;
  }

  #landing-subintro {
    font-size: 12px;
  }
  
  .landing-card .card-title{
    font-size: 13px;
  }
  
  #landing-find {
    color : white;
    margin-left: 20vw;
    font-size : 70px;
    margin-top: 15vh;
  }

  #landing-card-icon {
    margin-bottom: 2.5vh;
    height: 17vh;
  }

  #landing-card-bw-icon {
    height: 16vh;
    width: 19vw;
    padding-right: 1.7rem;
    margin-bottom: 3vh;
  }

  .box-explore {
    border: .2rem solid;
    border-radius: 5px;
    min-height: 10vh; 
    max-height: 14vh;
    max-width: 8vw;
  }

  .box-style {
    min-height: 3vh;
    max-height: 15vh;
    min-width: 90vw;
    align-items: flex-end;
    text-overflow: clip;
  }

  .box-style h4 {
    font-size: 18px;
    padding-top: 3.5vh;
  }

  .offcanvas.offcanvas-bottom {
    height: 60vh !important;
  }

  .offcanvas-header .btn-close {
    margin-left: 93vw;
  }

  .box-unpad {
    margin-left: -1%;
  }

  #entity-img {
    height:70vh;
    width: 100vw;
    object-fit: cover; 
    max-width: unset;
    margin-left:unset;
  }

  .leaflet-container {
    height:40vh;
    width:100vw;
    margin-bottom: 2vh;
  }

  #side-carousel {
    margin-top: .1rem;
  }

  #categories-div {
    background-color: #fff;
    border: .2rem solid #000;
    left: 94vw;
    bottom: 3.2vh;
    padding-bottom: 42px;
    padding-right: 41px;
    position: sticky;
  }

  #categories-button, #categories-div {
    height: 10vh;
    width: 6vw;
    padding-right: .3rem;
  }

  #entity-title  {
    font-size: 38px;
    margin-bottom: 5vh;
    margin-top: 4vh;
    margin-left: 1.5vw;
  }

  #entity-par {
    font-size: 20px;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
  }

  .entity-par {
    margin-left: .7rem;
    margin-right: .7rem;
  }

  #articles-subtitle {
    margin-bottom: 4vh !important;
  }

  .portal-title {
    margin-left: -50vw;
  }

  .box-patrimonio {
    background-color : var(--patrimonio-icon-color);
    width: 90vw;
  }
  
  .box-gastronomia {
    background-color : var(--gastronomia-icon-color);
    width: 90vw;
  }
  
  .box-natureza {
    background-color : var(--natureza-icon-color);
    width: 90vw;
  }
  
  .box-eventos {
    background-color : var(--eventos-icon-color);
    width: 90vw;
  }
  
  .box-dormir {
    background-color : var(--dormir-icon-color);
    width: 90vw;
  }  

  .box-icon-patrimonio-in {
    border-color: var(--patrimonio-icon-color);
  }

  .box-icon-gastronomia-in {
    border-color: var(--gastronomia-icon-color);
  }
  
  .box-icon-natureza-in {
    border-color: var(--natureza-icon-color);
  }
  
  .box-icon-eventos-in {
    border-color: var(--eventos-icon-color);
  }
  
  .box-icon-dormir-in {
    border-color: var(--dormir-icon-color);
  }
  
  .box-icon-comer-in {
    border-color: var(--comer-icon-color);
  }

  .card-container {
    margin-top:2vh;
  }

    /*
      SEARCH PAGE
    */

    .results h3 {
      text-transform: uppercase;
      font-weight: bold;
      text-overflow: ellipsis;
    }

    .results h5 {
      padding-top: -2.5vh;
      font-size: 18px;
    }

    .results img {
      height: 32vh;
      width: 100vh;
    }

    #results-search {
      margin-left: 80vw;
      margin-top: -16vh;
    }

    .filter-drop#img, #categories-banner {
      height: 25vh;
    }

    #categories-banner h1 {
      margin-top: 6.5vh;
      font-size: 30px;
    }

    #searchForm {
      margin-top: 2.5vh;
    }

}